import RegistrationFourthStep from '../RegistrationFourthStep/RegistrationFourthStep'
import ShowPasswordIcon from '../../../icons/login/ShowPasswordIcon'
import { register } from '../../../store/actions/user&balance'
import Typography from '../components/Typography/Typography'
import { SignupInitialValues, SignupSchema } from './formik'
import FormInput from '../components/FormInput/FormInput'
import Checkbox from '../components/Checkbox/Checkbox'
import Envelope from '../../../icons/login/Envelope'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import Button from '../components/Button/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from './SignUpForm.module.scss'
import 'react-phone-number-input/style.css'
import Tabs from '../components/Tabs/Tabs'
import { SIGNUP_TABS } from './constants'
import { useFormik } from 'formik'

const SignUpForm = ({ setLoginModalActive, setUserKey }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isSmsSent, setIsSmsSent] = useState(false)
  const [isCodeSent, setIsCodeSent] = useState(false)

  const [captcha, setCaptcha] = useState('')
  const [checked, setChecked] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [viaEmailStep, setViaEmailStep] = useState(1)
  const [repeatPassword, setRepeatPassword] = useState('')

  const [error, setError] = useState({})
  const [isError, setIsError] = useState(false)

  const handleCaptcha = (val) => {
    setCaptcha(val)
    formikSignup.setFieldValue('captcha_v2', val)
  }

  const urlOrgin = new URL(window.location.href).origin
  const finsovetnik =
    urlOrgin === process.env.REACT_APP_PROD_FA_URL ||
    urlOrgin === process.env.REACT_APP_TEST_FA_URL

  const formikSignup = useFormik({
    initialValues: SignupInitialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      try {
        const result = await register(values, finsovetnik)

        if (result?.data?.ok) {
          localStorage.setItem('token', result.data.token)
          setUserKey(result.data.ukey)
          setLoginModalActive(false)
          setIsError(false)
        }
      } catch (error) {
        setError(error.response)
        setIsError(true)
      }
    },
    validateOnMount: true
  })

  useEffect(() => {
    if (error?.data?.code === 401001) {
      setIsSmsSent(true)
    } else if (error?.data?.code === 401002) {
      setIsCodeSent(true)
    }
  }, [error])

  useEffect(() => {
    if (isCodeSent) {
      setViaEmailStep((prev) => prev + 1)
    }
  }, [isCodeSent])

  useEffect(() => {
    formikSignup.resetForm()

    setViaEmailStep(1)
    setRepeatPassword('')
    if (activeIndex === 0) {
      formikSignup.setFieldValue('acc_type', 'phone')
    } else {
      formikSignup.setFieldValue('acc_type', 'email')
    }
  }, [activeIndex])

  const handleSignupButtonDisable = () => {
    if (activeIndex === 0 && !isSmsSent) {
      return !formikSignup.values.login || !captcha || !checked
    }
    if (activeIndex === 0 && isSmsSent) {
      return !formikSignup.values.code
    }
    if (activeIndex === 1 && viaEmailStep === 1) {
      return !formikSignup.values.login || !captcha || !checked
    }
    if (activeIndex === 1 && viaEmailStep === 2) {
      return !formikSignup.values.pass
    }
    if (activeIndex === 1 && viaEmailStep === 3) {
      return !formikSignup.values.code
    }
  }

  const renderButtonText = () => {
    if (activeIndex === 0 && !isSmsSent) {
      return 'Получить sms-код'
    }
    if (activeIndex === 0 && isSmsSent) {
      return 'Войти'
    }
    if (activeIndex === 1 && viaEmailStep === 1) {
      return 'Продолжить'
    }
    if (activeIndex === 1 && viaEmailStep === 2) {
      return 'Сохранить'
    }
    if (activeIndex === 1 && viaEmailStep === 3) {
      return 'Зарегистрироваться'
    }
  }

  const preventCopyPaste = (e) => {
    e.preventDefault()
  }

  return (
    <section className={styles['signup-page']}>
      <div className={styles.container}>
        <div className={styles['form-container']}>
          {viaEmailStep === 3 ? (
            <div className={styles.header}>
              <Envelope />
              <Typography as="h3" color="#4285F4" className={styles.title}>
                Мы выслали вам код <br /> подтверждения регистрации
              </Typography>
              <Typography as="h3" color="#898989" className={styles.subtitle}>
                Введите код для подтверждения регистрации в поле ниже. Если вам
                не пришло письмо, проверьте папку “Спам”.
              </Typography>
            </div>
          ) : (
            <div className={styles.header}>
              <Typography as="h3" className={styles.title}>
                Добро пожаловать!
              </Typography>
              <Typography as="h3" className={styles.subtitle}>
                Регистрация
              </Typography>
            </div>
          )}
          {viaEmailStep !== 3 ? (
            <Tabs
              tabs={SIGNUP_TABS}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          ) : null}
          <div className={styles['form-fields']}>
            {activeIndex === 0 ? (
              isSmsSent ? (
                <RegistrationFourthStep formik={formikSignup} size={4} />
              ) : (
                <div className={styles['phone-input-wrapper']}>
                  <PhoneInput
                    className={
                      isError
                        ? `${styles['phone-input']} ${styles.invalid}`
                        : styles['phone-input']
                    }
                    international
                    defaultCountry="RU"
                    value={formikSignup.values.login}
                    onChange={(value) => {
                      formikSignup.setFieldValue('login', value)
                    }}
                  />
                  {isError && (
                    <Typography
                      className={styles['code-invalid']}
                      color="#F84708"
                    >
                      {error?.data?.code === 403007
                        ? 'Пользователь уже зарегистрирован'
                        : error?.data?.code === 403005
                          ? 'Некорректный номер телефона'
                          : ''}
                    </Typography>
                  )}
                </div>
              )
            ) : (
              <div className={styles['via-email-steps']}>
                {viaEmailStep === 1 ? (
                  <FormInput
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="E-mail"
                    className={
                      isError
                        ? `${styles.input} ${styles.invalid}`
                        : styles.input
                    }
                    value={formikSignup.values.login}
                    onChange={(e) => {
                      formikSignup.setFieldValue('login', e.target.value)
                    }}
                  />
                ) : viaEmailStep === 2 ? (
                  <div className={styles['via-email-passwords']}>
                    {activeIndex === 1 ? (
                      <>
                        <div className={styles['password-input-wrapper']}>
                          <FormInput
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            label="Password"
                            placeholder="Пароль"
                            className={
                              formikSignup.values.pass &&
                              formikSignup.errors.pass
                                ? `${styles.input} ${styles.password} ${styles.invalid}`
                                : `${styles.input} ${styles.password}`
                            }
                            value={formikSignup.values.pass}
                            onChange={(e) => {
                              formikSignup.setFieldValue('pass', e.target.value)
                            }}
                          />
                          <div
                            className={styles['show-password-icon']}
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            <ShowPasswordIcon show={showPassword} />
                          </div>
                          {formikSignup.values.pass &&
                            formikSignup.errors.pass && (
                              <Typography
                                className={styles['code-invalid']}
                                color="#F84708"
                              >
                                Минимальная длина пароля - 6 символов
                              </Typography>
                            )}
                        </div>
                        <div className={styles['password-input-wrapper']}>
                          <FormInput
                            id="repeatPassword"
                            type={showRepeatPassword ? 'text' : 'password'}
                            name="repeatPassword"
                            label="repeatPassword"
                            placeholder="Повторите пароль"
                            className={
                              repeatPassword &&
                              formikSignup.values.pass !== repeatPassword
                                ? `${styles.input} ${styles.password} ${styles.invalid}`
                                : `${styles.input} ${styles.password}`
                            }
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            onCopy={(e) => preventCopyPaste(e)}
                            onPaste={(e) => preventCopyPaste(e)}
                            onCut={(e) => preventCopyPaste(e)}
                          />
                          <div
                            className={styles['show-password-icon']}
                            onClick={() =>
                              setShowRepeatPassword((prev) => !prev)
                            }
                          >
                            <ShowPasswordIcon show={showRepeatPassword} />
                          </div>
                          {isError && (
                            <Typography
                              className={styles['code-invalid']}
                              color="#F84708"
                            >
                              {error?.data?.code === 403007 &&
                                'Пользователь с таким email уже зарегистрирован'}
                            </Typography>
                          )}
                          {repeatPassword &&
                            formikSignup.values.pass !== repeatPassword && (
                              <Typography
                                className={styles['code-invalid']}
                                color="#F84708"
                              >
                                Пароли не совпадают
                              </Typography>
                            )}
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : viaEmailStep === 3 ? (
                  <FormInput
                    id="code"
                    type="text"
                    name="code"
                    label=""
                    placeholder="Ключ из письма"
                    className={styles.input}
                    onChange={(e) => {
                      formikSignup.setFieldValue('code', e.target.value)
                    }}
                  />
                ) : null}
              </div>
            )}
          </div>
          {viaEmailStep !== 2 && viaEmailStep !== 3 ? (
            <div className={styles.terms}>
              <Checkbox
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <Typography as="p" className={styles['terms-text']}>
                При регистрации и входе вы соглашаетесь с{' '}
                <a href="#">условиями использования</a> и
                <a href="#"> Политикой обработки данных</a>
              </Typography>
            </div>
          ) : null}
          {viaEmailStep !== 2 && viaEmailStep !== 3 ? (
            <div className={styles.recaptcha}>
              <ReCAPTCHA
                sitekey="6Lft9gIhAAAAAC6Q6D3EQMVJMEXC5vG18Chth6Ym"
                onChange={handleCaptcha}
              />
            </div>
          ) : null}
          <Button
            type="primary"
            width="100%"
            backgroundColor="#4285F4"
            onClick={() => {
              if (activeIndex === 1) {
                if (viaEmailStep === 1 && !isError) {
                  setViaEmailStep((prev) => prev + 1)
                } else {
                  formikSignup.handleSubmit()
                }
              } else {
                formikSignup.handleSubmit()
              }
            }}
            isDisabled={handleSignupButtonDisable()}
          >
            <Typography className={styles['button-text']}>
              {renderButtonText()}
            </Typography>
          </Button>
        </div>
      </div>
    </section>
  )
}

export default SignUpForm
