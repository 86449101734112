import {
  InitialValuesEmail,
  InitialValuesPhone,
  SigninSchemaEmail,
  SigninSchemaPhone
} from './formik'
import RegistrationFourthStep from '../RegistrationFourthStep/RegistrationFourthStep'
import ShowPasswordIcon from '../../../icons/login/ShowPasswordIcon'
import ProfinancyLogo from '../../../icons/login/ProfinancyLogo'
import Typography from '../components/Typography/Typography'
import { login } from '../../../store/actions/user&balance'
import FormInput from '../components/FormInput/FormInput'
import styles from './LoginViaProfinancy.module.scss'
import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-number-input'
import Button from '../components/Button/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import 'react-phone-number-input/style.css'
import Tabs from '../components/Tabs/Tabs'
import { LOGIN_TABS } from './constants'
import { useFormik } from 'formik'

const LoginViaProfinancy = ({ setType, setLoginModalActive, setUserKey }) => {
  const [showPassword, setShowPassword] = useState(false)

  const [activeIndex, setActiveIndex] = useState(0)

  const [isSmsSent, setIsSmsSent] = useState(false)
  const [error, setError] = useState({})
  const [isError, setIsError] = useState(false)

  const urlOrgin = new URL(window.location.href).origin
  const finsovetnik =
    urlOrgin === process.env.REACT_APP_PROD_FA_URL ||
    urlOrgin === process.env.REACT_APP_TEST_FA_URL

  const formik = useFormik({
    initialValues: activeIndex === 0 ? InitialValuesPhone : InitialValuesEmail,
    validationSchema: activeIndex === 0 ? SigninSchemaPhone : SigninSchemaEmail,
    onSubmit: async (values) => {
      try {
        const result = await login(values, finsovetnik)
        if (result?.data?.ok) {
          localStorage.setItem('token', result.data.token)
          setUserKey(result.data.ukey)
          setLoginModalActive(false)
          setIsError(false)
        }
      } catch (err) {
        setError(err.response)
        setIsError(true)
      }
    },
    validateOnMount: true
  })

  useEffect(() => {
    if (error?.data?.code === 401001) {
      setIsSmsSent(true)
    }
  }, [error])

  useEffect(() => {
    formik.resetForm()

    if (activeIndex === 0) {
      formik.setFieldValue('acc_type', 'phone')
    } else {
      formik.setFieldValue('acc_type', 'email')
    }
  }, [activeIndex])

  const handleCaptcha = (val) => {
    formik.setFieldValue('captcha_v2', val)
  }

  return (
    <section className={styles['login-page']}>
      <div className={styles.container}>
        <div className={styles['form-container']}>
          <div className={styles.header}>
            <div className={styles.header}>
              <ProfinancyLogo />
              <Typography as="h3" className={styles.subtitle}>
                Войдите, используя свой e-mail и пароль <br /> или телефон, если
                yже зарегистрированы
                <br />
                на сайте <a href="https://profinansy.ru/">profinansy.ru</a>
              </Typography>
            </div>
          </div>
          <Tabs
            tabs={LOGIN_TABS}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <div className={styles['form-fields']}>
            {activeIndex === 0 ? (
              isSmsSent ? (
                <RegistrationFourthStep formik={formik} size={4} />
              ) : (
                <div className={styles['phone-input-wrapper']}>
                  <PhoneInput
                    className={
                      isError
                        ? `${styles['phone-input']} ${styles.invalid}`
                        : styles['phone-input']
                    }
                    international
                    defaultCountry="RU"
                    onChange={(value) => {
                      formik.setFieldValue('login', value)
                    }}
                  />
                  {isError && (
                    <Typography
                      className={styles['code-invalid']}
                      color="#F84708"
                    >
                      Некорректный номер телефона
                    </Typography>
                  )}

                  <div className={styles.recaptcha}>
                    <ReCAPTCHA
                      sitekey="6Lft9gIhAAAAAC6Q6D3EQMVJMEXC5vG18Chth6Ym"
                      onChange={handleCaptcha}
                    />
                  </div>
                </div>
              )
            ) : (
              <FormInput
                id="email"
                type="email"
                name="email"
                label="Email"
                placeholder="E-mail"
                className={
                  isError ? `${styles.input} ${styles.invalid}` : styles.input
                }
                onChange={(e) => {
                  formik.setFieldValue('login', e.target.value)
                }}
              />
            )}
            {activeIndex === 1 ? (
              <div className={styles['password-input-wrapper']}>
                <FormInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  label="Password"
                  placeholder="Введите пароль"
                  className={
                    isError ? `${styles.input} ${styles.invalid}` : styles.input
                  }
                  onChange={(e) => {
                    formik.setFieldValue('pass', e.target.value)
                  }}
                />
                <div
                  className={styles['show-password-icon']}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  <ShowPasswordIcon show={showPassword} />
                </div>
                {isError && (
                  <Typography
                    className={styles['code-invalid']}
                    color="#F84708"
                  >
                    Неверный логин или пароль
                  </Typography>
                )}
              </div>
            ) : null}
          </div>
          {activeIndex === 1 ? (
            <div className={styles['form-footer']}>
              <div className={styles['form-footer-top']}>
                <div
                  className={styles['forgot-password']}
                  onClick={() => setType('forgotPass')}
                >
                  <Typography color="#686868">Забыли пароль?</Typography>
                </div>
              </div>
            </div>
          ) : null}

          <Button
            type="primary"
            width="100%"
            backgroundColor="#4285F4"
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            <Typography className={styles['button-text']}>
              {activeIndex === 0 && !isSmsSent ? 'Получить смс-код' : 'Войти'}
            </Typography>
          </Button>
          <div className={styles.footer}>
            <Typography color="#C0C0C0">Нет аккаунта?</Typography>
            <div
              className={styles['to-signup-btn']}
              role="presentation"
              onClick={() => setType('signup')}
            >
              <Typography>Зарегистрируйтесь</Typography>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginViaProfinancy
